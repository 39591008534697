<template>
  <div
    :class="[
      'container mx-auto max-w-screen-xl px-4',
    ]"
  >
        <slot></slot>
    </div>
</template>
<script>
import {defineComponent} from "vue";
import {usePage} from "@inertiajs/vue3";

export default defineComponent({
  computed: {
    isRentPage() {
      return usePage().url === '/rent'
    },
  },
})
</script>
